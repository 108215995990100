import { useGetWishlistIdQuery } from '@magentoTypes'
import { Image } from '~/elements/Image/Image'
import { useQueryClient } from '@tanstack/react-query'
import { signOut } from 'next-auth/react'
import { useRouter } from 'next/router'
import { CART_ID, DEFAULT_LOCALE } from '~/config/constants'
import { ClientOnly } from '~/elements/ClientOnly/ClientOnly'
import { createMagentoClient } from '~/graphql/magentoClient'
import useLocalStorage from '~/hooks/useLocalStorage'
import { useSession } from '~/hooks/useSession'
import { createLanguageRegionLocale } from '~/lib/createLanguageRegionLocale'

export const WishlistIcon = () => {
  const [session] = useSession()
  const queryClient = useQueryClient()
  const [guestWishlistSku] = useLocalStorage<string[]>('guestWishlistSku', [])
  const router = useRouter()
  const [cartId, setCartId] = useLocalStorage<string>(CART_ID, '')
  const finalLocale = router.locale ? createLanguageRegionLocale(router.locale) : DEFAULT_LOCALE
  const magentoClient = createMagentoClient({
    usePost: false,
    queryClient,
    locale: finalLocale,
  })
  const logout = async () => {
    setCartId('')
    await queryClient.invalidateQueries()
    await signOut({ callbackUrl: '/' })
  }
  const { data, error } = useGetWishlistIdQuery(magentoClient, {}, { enabled: !!session })

  // Make sure that the user is authenticated before showing the wishlist icon
  if (error) {
    logout()
  }

  const calcAmount = () => {
    if (!!session) {
      if (!data) return ''
      return (data?.customer?.wishlists?.[0]?.items_v2?.items?.length as number) < 10
        ? data?.customer?.wishlists?.[0]?.items_v2?.items?.length
        : '9+'
    } else {
      return guestWishlistSku?.length < 10 ? guestWishlistSku?.length : '9+'
    }
  }
  return (
    <div className="relative flex flex-nowrap items-center">
      <Image
        src="/images/Icons/heart.svg"
        width={24}
        height={24}
        placeholder="empty"
        alt="heart-icon"
        className="min-w-[24px]"
      />
      <ClientOnly>{calcAmount() ? <span className="ml-1">{calcAmount()}</span> : null}</ClientOnly>
    </div>
  )
}
