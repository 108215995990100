import { ConfigurableProduct } from '@magentoTypes'
import { useEffect, useState, useCallback } from 'react'
import { useRouter } from 'next/router'
import { useProductsOfPopularSearchTermsQuery } from './../__generated__/magentoTypes'
import { useQueryClient } from '@tanstack/react-query'
import { createMagentoClient } from '~/graphql/magentoClient'
import { listFilters, KlevuFetch, search, KlevuKMCSettings, KlevuRecord } from '@klevu/core'
import { createLanguageRegionLocale } from '~/lib/createLanguageRegionLocale'
import { DEFAULT_LOCALE } from '~/config/constants'

export const useProductsOfPopularSearchTerms = (enabled: boolean) => {
  const queryClient = useQueryClient()
  const router = useRouter()
  const finalLocale = router.locale ? createLanguageRegionLocale(router.locale) : DEFAULT_LOCALE
  const magentoClient = createMagentoClient({ queryClient, locale: finalLocale })

  const [productSkuForSearchTerms, setProductSkuForSearchTerms] = useState<
    { sku: string; term: string }[]
  >([])

  const fetchFirstProductOfPopularSearchTerm = useCallback(async () => {
    const settings = await KlevuKMCSettings()
    const popularSearchTerms = settings?.root?.klevu_webstorePopularTerms

    if (enabled && popularSearchTerms?.length) {
      Promise.all(
        popularSearchTerms.map(async (searchTerm: string, index: number) => {
          const modifiers = [
            listFilters({
              include: ['sku'],
              //TODO: figuring out why exclude is not working and whether you can skip this
              exclude: ['benuta_color_filter', 'benuta_form_new', 'benuta_material'],
              rangeFilterSettings: [
                {
                  key: 'klevu_price',
                  minMax: true,
                },
              ],
            }),
          ]

          const functions = [
            search(
              searchTerm,
              {
                id: 'search',
                limit: 1,
              },
              ...modifiers,
            ),
          ]
          const res = await KlevuFetch(...functions)

          const searchResult = res.queriesById('search')
          if (!searchResult) {
            return ''
          }

          return searchResult.records[0]
        }),
      ).then((records) => {
        if (records && Array.isArray(records) && records.length) {
          setProductSkuForSearchTerms(
            (records as KlevuRecord[])
              .map((record) =>
                record?.sku
                  .split('-')
                  .filter((item: string, index: number) => index < 2)
                  .join('-'),
              )
              .map((item, i) => {
                return { sku: item, term: popularSearchTerms[i] }
              }),
          )
        }
      })
    }
  }, [enabled])

  useEffect(() => {
    fetchFirstProductOfPopularSearchTerm()
  }, [fetchFirstProductOfPopularSearchTerm])

  const { data, isLoading, error } = useProductsOfPopularSearchTermsQuery(
    magentoClient,
    { skus: productSkuForSearchTerms.map((item) => item.sku) },
    { enabled: enabled && !!productSkuForSearchTerms.length },
  )
  if (enabled && data?.products?.items?.length) {
    return {
      data: data.products.items
        .map((product) => {
          return product
            ? {
                term: productSkuForSearchTerms?.find(
                  (pair) => pair.sku === (product as ConfigurableProduct)?.sku,
                )?.term,
                product: product,
              }
            : null
        })
        .filter((item) => item !== null)
        .filter((pair, i) => i <= 3),
      isLoading,
      error,
    }
  }
  return { data: null, isLoading, Error }
}
