import { Item } from '@constructor-io/constructorio-client-javascript'
import { Image } from '~/elements/Image/Image'
import ProductCardBadges from '~/modules/PLP/components/ProductCardBadges'
import { AddToWishlist } from '~/elements/AddToWishlist/AddToWishlist'
import { useContext, useMemo } from 'react'
import FeaturesSection from '~/modules/PLP/components/FeaturesSection'
import PriceSection from '~/modules/PLP/components/PriceSection'
import { Link } from '~/elements/Link/Link'
import { mapPrice } from '~/modules/SearchResultPageCio/lib/mapPrice'
import { UIContext } from '~/lib/Context/UIStore'
import { hasBestseller, hasSalePrice, isNewProduct } from '~/lib/productBadgeChecks'
import {
  checkHasSustainability,
  checkIsCotton,
  checkIsHandmade,
  checkIsWashable,
  checkIsWolle,
} from '~/lib/getProductFeature'
import { useBrandType } from '~/hooks/useBrandType'
import ProductCardIcon from '~/modules/PLP/components/Icons/ProductCardIcon'
type SearchResultsProductTileProps = {
  product: Item
}
export const SearchResultsProductTile = ({ product }: SearchResultsProductTileProps) => {
  const { setSearchToggle, setMobileFlyoutToggle } = useContext(UIContext)

  const featureList = useMemo(
    () => ({
      hasSustainability: checkHasSustainability(product?.data?.benuta_test_seal_id as string),
      isHandmade: checkIsHandmade(product?.data?.benuta_production_type_id),
      isCotton: checkIsCotton(product?.data?.benuta_pile_material_id),
      isWolle: checkIsWolle(product?.data?.benuta_pile_material_id),
      isWashable: checkIsWashable(product?.data?.benuta_waschbar_id),
    }),
    [product?.data],
  )
  const isAnyFeature = useMemo(
    () =>
      featureList.isWolle ||
      featureList.isCotton ||
      featureList.hasSustainability ||
      featureList.isHandmade ||
      featureList.isWashable,
    [featureList],
  )

  const priceData = useMemo(
    () => ({
      price_range: {
        minimum_price: mapPrice(
          'min',
          product?.data?.parent_price,
          product?.data?.parent_special_price,
        ),
        maximum_price: mapPrice(
          'max',
          product?.data?.parent_price,
          product?.data?.parent_special_price,
        ),
      },
    }),
    [product.data],
  )

  const isSalePrice = useMemo(() => {
    return hasSalePrice(priceData)
  }, [priceData])

  const isBestseller = useMemo(() => {
    return hasBestseller(product?.data?.umsatz)
  }, [product?.data?.umsatz])

  const isNew = useMemo(() => {
    return isNewProduct(product?.data?.parent_created_at)
  }, [product?.data?.parent_created_at])

  const brandType = useBrandType({ manufacturer: product?.data?.manufacturer_id as number })

  return (
    <div
      className="flex h-full w-full flex-col"
      data-cnstrc-item-section="Products"
      data-cnstrc-item-name={product.value}
      data-cnstrc-item-id={product.data?.id}
    >
      <Link
        className="relative block h-[175px] w-full lg:h-[330px] lg:min-h-[330px]"
        href={`${product?.data?.url}.html`}
        title={product?.data?.parent_name}
        onClick={() => {
          setSearchToggle?.(false)
          setMobileFlyoutToggle?.(false)
        }}
      >
        {product?.data?.benuta_ar && (
          <div className="pointer-events-none absolute top-4 right-2.5 z-10 mr-5">
            <ProductCardIcon name="ar-2" className="h-4.5 w-4.5 lg:h-6 lg:w-6" />
          </div>
        )}
        <Image
          style={{
            objectFit: 'contain',
          }}
          fill
          sizes={'(max-width: 1024px) 50vw, 25vw'}
          src={product?.data?.parent_image_url ?? product?.data?.image_url}
          alt={product?.data?.parent_name}
        />
      </Link>
      <div>
        <div className="mt-5 flex justify-between">
          <ProductCardBadges
            newBadge={isNew}
            saleBadge={isSalePrice}
            bestsellerBadge={isBestseller}
          />
          <AddToWishlist
            className="!h-6 !w-6"
            isProductCard={true}
            sku={product.data?.id as string}
          />
        </div>
        <Link
          href={`${product?.data?.url}.html`}
          title={product?.data?.parent_name}
          className="leading-5.5 mt-2.5 mb-1 text-[15px] font-extralight"
          onClick={() => {
            setSearchToggle?.(false)
            setMobileFlyoutToggle?.(false)
          }}
        >
          {brandType && (
            <span className="text-[15px] leading-[18px] text-sg-dark-grey lg:text-15-22-sg">
              {brandType.name}{' '}
            </span>
          )}
          {product?.data?.parent_name}
        </Link>
        {isAnyFeature && (
          <FeaturesSection
            isWolle={featureList.isWolle}
            isCotton={featureList.isCotton}
            hasSustainability={featureList.hasSustainability}
            isHandmade={featureList.isHandmade}
            isWashable={featureList.isWashable}
          />
        )}
        <div className="mt-2.5 flex">
          <PriceSection hasSale={isSalePrice} productPrice={priceData.price_range} />
        </div>
      </div>
    </div>
  )
}
