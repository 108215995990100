import clsx from 'clsx'
import { useTranslation } from 'next-i18next'
import { Headline } from '~/elements/Headline/Headline'

type SearchNotFoundResultsProps = {
  searchValue: string
  className?: string
}

export const SearchNotFoundResults = ({ searchValue, className }: SearchNotFoundResultsProps) => {
  const { t } = useTranslation()

  return (
    <div className={clsx(className, 'flex w-full flex-col')}>
      <Headline
        className={clsx(
          'mt-12 mb-0 flex min-h-[56px] w-full items-center justify-center px-4 text-center lg:mt-4 lg:mb-2',
        )}
        type="h2"
      >
        {`${t('No search results for')} "${searchValue}"`}
      </Headline>
      <p className="mb-[30px] text-center lg:text-h4-desktop-large">{`${t(
        'Please try out a different search term',
      )}`}</p>
    </div>
  )
}
