import { listFilters, KlevuFetch, trendingProducts } from '@klevu/core'
import { useEffect, useState, useCallback } from 'react'

export const usePopularProducts = (numberOfProductsShown: number) => {
  const [popularProducts, setPopularProducts] = useState<any[]>([])
  const [loading, setLoading] = useState(false)

  const initalFetch = useCallback(async () => {
    setLoading(true)

    const modifiers = [
      listFilters({
        include: ['sku'],
        //TODO: figuring out why exclude is not working and whether you can skip this
        exclude: ['benuta_color_filter', 'benuta_form_new', 'benuta_material'],

        rangeFilterSettings: [
          {
            key: 'klevu_price',
            minMax: true,
          },
        ],
      }),
    ]

    const res = await KlevuFetch(
      trendingProducts(
        {
          limit: numberOfProductsShown,
        },
        ...modifiers,
      ),
    )
    setPopularProducts(res.queriesById('trendingProducts')?.records ?? [])
  }, [numberOfProductsShown])

  useEffect(() => {
    initalFetch()
  }, [initalFetch])

  useEffect(() => {
    if (popularProducts.length) {
      setLoading(false)
    }
  }, [popularProducts])

  return {
    popularProducts,
    loading,
  }
}
