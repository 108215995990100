import { animated, useSpring } from '@react-spring/web'
import { usePrefersReducedMotion } from 'hooks/usePrefersReducedMotion'
import { useCallback, useEffect, useState } from 'react'

type Props = {
  children: React.ReactNode
  x?: number
  y?: number
  rotation?: number
  scale?: number
  timing?: number
  springConfig?: {
    tension?: number
    friction?: number
  }
}
export const Boop = ({
  children,
  x = 0,
  y = 0,
  rotation = 15,
  scale = 1.2,
  timing = 150,
  springConfig = {
    tension: 300,
    friction: 10,
  },
}: Props) => {
  const [isBooped, setIsBooped] = useState(false)
  const prefersReducedMotion = usePrefersReducedMotion()
  const [style, api] = useSpring(() => ({
    transform: 'translate(0px, 0px) rotate(0deg) scale(1)',
    config: springConfig,
  }))
  api.start({
    transform: isBooped
      ? `translate(${x}px, ${y}px) rotate(${rotation}deg) scale(${scale})`
      : 'translate(0px, 0px) rotate(0deg) scale(1)',
  })

  useEffect(() => {
    if (!isBooped) {
      return
    }
    const timeoutId = window.setTimeout(() => {
      setIsBooped(false)
    }, timing)

    return () => {
      window.clearTimeout(timeoutId)
    }
  }, [isBooped, timing])
  const trigger = useCallback(() => {
    if (prefersReducedMotion) return
    setIsBooped(true)
  }, [prefersReducedMotion])

  return (
    <animated.span onMouseEnter={trigger} style={style}>
      {children}
    </animated.span>
  )
}
