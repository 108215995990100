import { useQuery } from '@tanstack/react-query'

export type PopularProduct = {
  id: string
  sku: string
  url: string
  name: string
  image: string
  imageHover: string
  startPrice?: string
}

const postHeaders = {
  method: 'POST',
  headers: {
    'Content-type': 'application/json',
  },
}

const klevuSearchNetUrl = process.env.NEXT_PUBLIC_KLEVU_URL || ''

const fetchRecentlySeenProducts = async (product_ids: string[], limit: number) => {
  if (product_ids?.length) {
    const response = await fetch(klevuSearchNetUrl, {
      ...postHeaders,
      body: JSON.stringify({
        context: { apiKeys: [process.env.NEXT_PUBLIC_KLEVU_KEY] },
        recordQueries: [
          {
            id: 'recslyViewed',
            typeOfRequest: 'SEARCH',
            settings: {
              customANDQuery: `id:(${product_ids?.join(' OR ')})`,
              topIds: product_ids?.map((id) => {
                return { key: 'id', value: id }
              }),
              typeOfRecords: ['KLEVU_PRODUCT'],
              limit: limit,
              fields: [
                'id',
                'name',
                'salePrice',
                'startPrice',
                'sku',
                'image',
                'imageHover',
                'url',
              ],
            },
          },
        ],
      }),
    })

    const results = await response.json()
    return results.queryResults[0].records
  }
  return null
}

export const useRecentlySeenProducts = (product_ids: string[], limit: number) =>
  useQuery<PopularProduct[]>(['recentlySeen', product_ids, limit], () =>
    fetchRecentlySeenProducts(product_ids, limit),
  )
