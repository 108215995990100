import { useEffect, useRef } from 'react'
import ConstructorIOClient from '@constructor-io/constructorio-client-javascript'
import { useRouter } from 'next/router'
import { DEFAULT_LOCALE } from '~/config/constants'
import { createLanguageRegionLocale } from '~/lib/createLanguageRegionLocale'
import { useSession } from '~/hooks/useSession'
import { getCioUserId } from '~/lib/cioHelpers'

const resolveApiKey = (locale: string): string | undefined => {
  if (process.env.NEXT_PUBLIC_CONSTRUCTORIO_API_KEYS) {
    const keys = JSON.parse(process.env.NEXT_PUBLIC_CONSTRUCTORIO_API_KEYS)

    return keys?.[locale]
  }

  return undefined
}

export const useCioClient = () => {
  const [session] = useSession()
  const router = useRouter()
  const locale = router.locale ? createLanguageRegionLocale(router.locale) : DEFAULT_LOCALE
  const cioClientRef = useRef<ConstructorIOClient | null>(null)

  useEffect(() => {
    const apiKey = resolveApiKey(locale)
    if (apiKey) {
      const options = {
        apiKey,
        userId: getCioUserId(session?.user?.email),
      }

      if (cioClientRef.current) {
        cioClientRef.current.setClientOptions(options)
      } else {
        cioClientRef.current = new ConstructorIOClient(options)
      }
    }

    return () => {
      if (cioClientRef.current) {
        cioClientRef.current = null
      }
    }
  }, [locale, session])

  return cioClientRef.current
}
