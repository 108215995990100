import React from 'react'
import { Image } from '~/elements/Image/Image'
import { Link } from '~/elements/Link/Link'
import { useTranslation } from 'next-i18next'
import { useSession } from '~/hooks/useSession'
import { MobileLanguage } from './MobileLanguage'

export const BottomSection = () => {
  const { t } = useTranslation()
  const [session] = useSession()
  return (
    <menu className="bg-grey-light px-4 pt-4 pb-20">
      <li className="py-4 text-p">
        {session ? (
          <Link href="/account" title={t('account')} className="flex items-center gap-6 text-p">
            <Image
              src={'/images/Icons/account.svg'}
              width={26}
              height={26}
              placeholder="empty"
              alt="account-icon"
              style={{ minWidth: '24px' }}
            />
            {t('account')}
          </Link>
        ) : (
          <Link href="/login" title={t('Login')} className="flex items-center gap-6 text-p">
            <Image
              src={'/images/Icons/account.svg'}
              width={26}
              height={26}
              placeholder="empty"
              alt="account-icon"
              style={{ minWidth: '24px' }}
            />
            {t('Login')}
          </Link>
        )}
      </li>
      <li className="py-4 text-p">
        <MobileLanguage />
      </li>
    </menu>
  )
}
