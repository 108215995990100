import { Item } from '@constructor-io/constructorio-client-javascript'
import { makeTextBold } from '~/lib/makeTextBold'
import parse from 'html-react-parser'
import { useTranslation } from 'next-i18next'
import { Image } from '@next/image'

export type SearchResultSuggestionsProps = {
  suggestions: Item[]
  className?: string
  handleSuggestionClick: (suggestion: string) => void
}
export const SearchResultSuggestions = ({
  suggestions,
  className,
  handleSuggestionClick,
}: SearchResultSuggestionsProps) => {
  const { t } = useTranslation()

  return (
    <div className={className}>
      {suggestions.length > 0 && (
        <p className="hidden pb-7.5 text-[15px] font-normal leading-[22px] lg:block">
          {t('Search suggestions')}
        </p>
      )}
      <ul className="grid gap-3.5">
        {suggestions.map((suggestion) => (
          <li
            className="flex cursor-pointer items-center justify-between font-extralight"
            key={suggestion.value}
            onClick={() => handleSuggestionClick(suggestion.value)}
            data-cnstrc-item-section="Search Suggestions"
            data-cnstrc-item-name={suggestion.value}
          >
            <div className="flex items-center gap-[10px]">
              <Image
                width={20}
                height={25}
                src={suggestion.data?.image_url}
                alt={suggestion?.matched_terms?.[0]}
              />
              <div className="leading-5.5 text-[15px]">
                {parse(makeTextBold(suggestion?.value, suggestion?.matched_terms?.[0]))}
              </div>
            </div>
            <div className="text-p-small text-sg-dark-grey">
              {suggestion?.data?.total_num_results}
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}
