import React from 'react'

const ArrowRight: React.FC<{ className?: string; stroke?: string }> = ({
  className,
  stroke = '#141414',
}) => {
  return (
    <svg className={className} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3 12L21 12" stroke={stroke} strokeWidth="1.3" strokeLinecap="round" />
      <path d="M15 18L21 12L15 6" stroke={stroke} strokeWidth="1.3" strokeLinecap="round" />
    </svg>
  )
}

export default ArrowRight
