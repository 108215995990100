import clsx from 'clsx'
import { useTranslation } from 'next-i18next'
import React from 'react'

type BanderoleCountDown = {
  days: string
  hours: string
  minutes: string
  seconds: string
}

const SingleDataBox: React.FC<{ number?: string; isDesktop?: boolean; label: string }> = ({
  number,
  isDesktop,
  label,
}) => {
  return (
    <div className={clsx('text-center', isDesktop && 'lgx:py-1')}>
      <div
        className={clsx(
          'min-w-[20px] text-[14px] font-bold',
          isDesktop ? 'lgx:text-p' : 'leading-none',
        )}
      >
        {number}
      </div>
      <div className="mt-px text-[6px] font-medium leading-normal lgx:mt-0">{label}</div>
    </div>
  )
}

const Colon: React.FC = () => {
  return <div className="mx-1 mb-2 text-center text-[12px] font-bold">:</div>
}

const BanderoleCountdown: React.FC<{
  countdownData: BanderoleCountDown
  isDesktop?: boolean
  className?: string
}> = ({ countdownData, isDesktop, className }) => {
  const { t } = useTranslation()

  return (
    <div className={clsx('flex max-w-fit items-center justify-center', className && className)}>
      <SingleDataBox number={countdownData?.days} isDesktop={isDesktop} label={t('Days')} />
      <Colon />
      <SingleDataBox number={countdownData?.hours} isDesktop={isDesktop} label={t('Hours')} />
      <Colon />
      <SingleDataBox number={countdownData?.minutes} isDesktop={isDesktop} label={t('Minutes')} />
      <Colon />
      <SingleDataBox number={countdownData?.seconds} isDesktop={isDesktop} label={t('Seconds')} />
    </div>
  )
}

export default BanderoleCountdown
