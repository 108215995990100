import { useTranslation } from 'next-i18next'
import clsx from 'clsx'
import React from 'react'
import { SearchProductCard } from './SearchProductCard'
import styles from '../Search.module.css'
import { usePopularProducts } from '~/hooks/usePopularProducts'
import { ProductGridLoading } from './SearchLoading'
type Props = {
  numberOfProductsShown: number
  isDoubleLine: boolean
}

export const PopularProducts = ({ numberOfProductsShown, isDoubleLine }: Props) => {
  const { popularProducts, loading } = usePopularProducts(numberOfProductsShown)

  const { t } = useTranslation()

  if (loading) {
    return <ProductGridLoading gridColumnsNumber={numberOfProductsShown} isOneLine={true} />
  }

  if (popularProducts?.length) {
    return (
      <>
        <h2 className={clsx('pb-8 pt-12 lgx:py-0', styles.searchHeading)}>
          {t('Popular products')}
        </h2>
        <ul className={clsx(styles.productGrid)}>
          {popularProducts?.length
            ? popularProducts.map((product: any, i: number) => (
                <SearchProductCard
                  index={i}
                  key={product.id}
                  product={product}
                  noBorderTopBottom={!isDoubleLine}
                  totalProdNumber={numberOfProductsShown}
                  actualProdNumber={popularProducts.length}
                />
              ))
            : null}
        </ul>
      </>
    )
  }

  return null
}
