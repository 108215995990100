import { ConfigurableProduct } from '@magentoTypes'
import { Image } from '@next/image'
import clsx from 'clsx'
import { useMemo } from 'react'
import { Link } from 'elements/Link/Link'

type Props = {
  product: ConfigurableProduct
  hasBorderRight: boolean
  hasBorderTop: boolean
  hasPriority?: boolean
  className?: string
  colCount?: number
  searchTerm: string
  isLastTwo: boolean
}

export const SearchTermProductCard = ({
  product,
  hasBorderRight,
  hasPriority,
  className,
  searchTerm,
  isLastTwo,
}: Props) => {
  const backgroundImage = useMemo(() => {
    if (!product) return null
    const images = product?.media_gallery
      ?.filter((item) => !item?.disabled)
      .find((item) => item?.position === 1)
    return images
  }, [product])

  return (
    <figure
      id={searchTerm}
      className={clsx(
        'relative mb-10 flex items-center justify-center border-t border-grey-border border-opacity-50 lg:mb-0  lg:border-t-0',
        hasBorderRight ? 'border-r' : '',
        isLastTwo ? 'border-b lg:border-b-0' : 'border-b-0',
        className,
      )}
    >
      <div className={clsx('group relative flex aspect-[4/5] w-full flex-col items-center')}>
        {backgroundImage?.url ? (
          <div
            className={clsx(
              'pointer-events-none absolute z-10 hidden h-full w-full opacity-0 transition-opacity duration-500 ease-in-out group-hover:pointer-events-auto group-hover:opacity-100 lg:block',
            )}
          >
            <Link
              href={`search?q=${encodeURIComponent(searchTerm)}`}
              title={product?.name as string}
              className="relative block h-full w-full"
            >
              <Image
                src={backgroundImage?.url}
                alt={backgroundImage?.label || ''}
                fill
                style={{
                  objectPosition: 'center',
                  objectFit: 'cover',
                }}
                sizes="(min-width: 768px) 25vw, 50vw"
              />
            </Link>
          </div>
        ) : null}
        {product?.image?.url ? (
          <div className="relative flex  h-full w-full flex-col items-center justify-center">
            <Link
              href={`/`}
              title={product?.name as string}
              className="relative block h-full w-full"
            >
              <Image
                src={product?.image?.url}
                alt={product?.image?.label as string}
                fill
                style={{
                  objectPosition: 'center',
                  objectFit: 'cover',
                }}
                className="h-full w-full"
                sizes="(min-width: 768px) 25vw, 50vw"
                priority={!!hasPriority}
              />
            </Link>
          </div>
        ) : null}

        <figcaption className="h4-mobile lg:h4-desktop-large -mb-10 w-full justify-center place-self-end text-center lg:-mb-[50px] lg:text-h4-desktop-large">
          {searchTerm}
        </figcaption>
      </div>
    </figure>
  )
}
