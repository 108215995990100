import { PriceRange } from '@magentoTypes'
type PriceData = {
  price_range: PriceRange
}
export const hasSalePrice = (priceData: PriceData) => {
  const regularPrice = priceData.price_range?.minimum_price?.regular_price?.value
  const finalPrice = priceData.price_range?.minimum_price?.final_price?.value

  if (regularPrice && finalPrice) {
    return finalPrice !== regularPrice
  }

  return false
}

export const isNewProduct = (createdAt: string) => {
  const createdAtDate = new Date(createdAt)
  const currentDate = new Date()
  const timeDifference = currentDate.getTime() - createdAtDate.getTime()
  const daysDifference = timeDifference / (1000 * 60 * 60 * 24)

  return daysDifference <= 180
}

export const hasBestseller = (umsatz: number) => {
  return !!umsatz && umsatz >= 0 && umsatz <= 35
}
