import { useTranslation } from 'next-i18next'
import { SearchProductCard } from './SearchProductCard'
import React from 'react'
import { useRecentlySeenProducts, PopularProduct } from '~/hooks/useRecentlySeenProducts'
import clsx from 'clsx'
import styles from '../Search.module.css'
import { ProductGridLoading } from './SearchLoading'

type Props = {
  product_ids: string[]
  maxNumberOfProductsShown: number
}

export const RecentlyViewedProducts = ({ product_ids, maxNumberOfProductsShown }: Props) => {
  const { t } = useTranslation()
  const { isFetching, isError, data } = useRecentlySeenProducts(
    product_ids,
    maxNumberOfProductsShown,
  )

  if (isError || !data || !data.length) {
    return null
  }

  if (isFetching) {
    return <ProductGridLoading gridColumnsNumber={maxNumberOfProductsShown} isOneLine={true} />
  }

  return (
    <>
      <h2
        className={clsx(
          styles.searchHeading,
          'mt-8 border-b border-grey-border pb-8 pt-8 lgx:border-t lgx:border-b-0 lgx:pt-8  lgx:pb-3',
        )}
      >
        {t('Recently viewed')}
      </h2>
      <ul className="grid w-full grid-cols-2 border-b border-grey-border md:grid-cols-3 lgx:mt-6 lgx:grid-cols-4 lgx:border-b-0 lgx:pr-16 xl:mt-10 xl:pr-20 2xl:grid-cols-6">
        {data?.map((product: PopularProduct, i: number) => (
          <SearchProductCard
            index={i}
            key={product.id}
            product={product}
            totalProdNumber={maxNumberOfProductsShown}
            isRecentlyViewedProduct
            actualProdNumber={data?.length}
          />
        ))}
      </ul>
    </>
  )
}
