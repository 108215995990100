import { Image } from '~/elements/Image/Image'
import { ConfigurableCartItem, useCartQuery, useFetchCartHeaderQuery } from '@magentoTypes'
import { useQueryClient } from '@tanstack/react-query'
import { createMagentoClient } from '~/graphql/magentoClient'
import { useCartId, getCartId } from '~/hooks/useCartId'
import { toast } from 'react-toastify'
import { useRouter } from 'next/router'
import { createLanguageRegionLocale } from '~/lib/createLanguageRegionLocale'
import { DEFAULT_LOCALE } from '~/config/constants'
import { parseMagentoGraphqlError, resettableErrors } from '~/lib/parseMagentoGraphlError'
import { useEffect, useState } from 'react'

export const CartIcon = () => {
  const queryClient = useQueryClient()
  const [cartId, loading, resetCartId] = useCartId(!!getCartId())
  const [identified, hasIdentified] = useState(false)
  const router = useRouter()
  const finalLocale = router.locale ? createLanguageRegionLocale(router.locale) : DEFAULT_LOCALE
  const magentoClient = createMagentoClient({ usePost: false, queryClient, locale: finalLocale })
  const { data } = useFetchCartHeaderQuery(
    magentoClient,
    {
      cartId,
    },
    {
      enabled: !!cartId,
      retry: false,
      async onError(error) {
        const parsedError = parseMagentoGraphqlError(error)
        if (
          typeof parsedError?.extensions?.category !== 'undefined' &&
          resettableErrors.includes(parsedError?.extensions?.category)
        ) {
          await resetCartId()
        } else {
          toast.error(JSON.parse(JSON.stringify(error))?.response?.errors?.[0]?.message)
        }
      },
    },
  )
  useEffect(() => {
    if (process.env.NEXT_PUBLIC_DOTDIGITAL_PROGRAM_ID && data?.cart?.email) {
      if (!identified) {
        ;(window as any)?.dmPt('identify', data?.cart?.email)
        hasIdentified(true)
      }

      if (data?.cart?.total_quantity) {
        const lineItems = [] as any[]
        const getSelectedShippingMethod =
          data?.cart?.shipping_addresses?.[0]?.available_shipping_methods?.find((method) => {
            return (
              method?.method_code ===
              data.cart?.shipping_addresses[0]?.selected_shipping_method?.method_code
            )
          })

        const selectedShippingCosts =
          data.cart?.shipping_addresses?.[0]?.selected_shipping_method?.amount ?? false
        const selectedShippingMethod =
          data.cart?.shipping_addresses?.[0]?.selected_shipping_method?.method_title ?? false
        const selectedShippingCostsInclTax =
          getSelectedShippingMethod?.price_incl_tax ?? selectedShippingCosts
        const grandTotalWithoutDiscount = data?.cart?.prices?.subtotal_including_tax?.value
          ? getSelectedShippingMethod?.price_incl_tax?.value
            ? data?.cart?.prices?.subtotal_including_tax?.value +
              getSelectedShippingMethod?.price_incl_tax?.value
            : data?.cart?.prices?.subtotal_including_tax?.value
          : null

        ;(data?.cart?.items as ConfigurableCartItem[]).forEach((item) => {
          lineItems.push({
            sku: item?.configured_variant?.sku,
            name: item?.product?.name,
            unitPrice: item?.configured_variant?.price_range?.minimum_price?.regular_price?.value,
            salePrice: item?.configured_variant?.price_range?.minimum_price?.final_price?.value,
            quantity: item?.quantity,
            totalPrice: item?.prices?.row_total_including_tax?.value,
            imageUrl: item?.product?.image?.url,
            productUrl: `https://${
              process.env.NEXT_PUBLIC_VERCEL_ENV == 'production'
                ? process.env.NEXT_PUBLIC_DOMAIN_NAME
                : process.env.NEXT_PUBLIC_VERCEL_URL
            }/${item?.product?.url_rewrites?.[0]?.url}`,
          })
        })
        ;(window as any)?.dmPt('cartInsight', {
          programID: process.env.NEXT_PUBLIC_DOTDIGITAL_PROGRAM_ID,
          cartDelay: parseInt((process.env.NEXT_PUBLIC_DOTDIGITAL_CART_DELAY || '30') as string),
          cartID: data?.cart?.id,
          cartPhase: 'CUSTOMER_LOGIN',
          currency: data?.cart?.prices?.subtotal_including_tax?.currency || 'EUR',
          subtotal: data?.cart?.prices?.subtotal_including_tax?.value,
          grandTotal: data?.cart?.prices?.grand_total?.value,
          shipping:
            selectedShippingCostsInclTax && selectedShippingMethod
              ? selectedShippingCostsInclTax.value
              : 0,
          discountAmount: Math.abs(
            (data?.cart?.prices?.grand_total?.value as number) -
              (grandTotalWithoutDiscount as number),
          ),
          taxAmount: data?.cart?.prices?.applied_taxes?.[0]?.amount?.value,
          cartUrl: `https://${
            process.env.NEXT_PUBLIC_VERCEL_ENV == 'production'
              ? process.env.NEXT_PUBLIC_DOMAIN_NAME
              : process.env.NEXT_PUBLIC_VERCEL_URL
          }/cart`,
          lineItems,
        })
      }
    }
  }, [data])

  return (
    <>
      <div className="relative flex flex-nowrap">
        <Image
          src="/images/Icons/cart.svg"
          width={24}
          height={24}
          placeholder="empty"
          alt="cart-icon"
          style={{ minWidth: '24px' }}
        />{' '}
        {data?.cart?.total_quantity ? (
          <span className="-right-3 mt-1 lgx:absolute">{data?.cart?.total_quantity}</span>
        ) : null}
      </div>
    </>
  )
}
