import { Menu, MenuButton, MenuList, MenuItem } from '@reach/menu-button'
import { useTranslation } from 'next-i18next'
import { Image } from '~/elements/Image/Image'
import { possibleStores } from '~/config/constants'
import { useRouter } from 'next/router'
import cookies from 'js-cookie'
import { useQueryClient } from '@tanstack/react-query'

type Props = {}
export const MobileLanguage = ({}: Props) => {
  const router = useRouter()
  const queryClient = useQueryClient()
  const { t } = useTranslation('languages/languages')
  const currentLand = process.env.NEXT_PUBLIC_COUNTRY_CODE
  const currentLang = router.locale?.slice(0, 2)
  return (
    <div className="flex items-center ">
      <Menu id="language-menu">
        <MenuButton className="flex items-center gap-5">
          <Image
            src={`/images/Flags/${currentLand}.svg`}
            placeholder="empty"
            width={16}
            height={16}
            alt={currentLand ?? 'flag-icon'}
          />{' '}
          <span className="ml-2">
            {
              possibleStores.find(
                (store) =>
                  store.land == currentLand &&
                  store.lang.toLowerCase() == currentLang?.toLocaleLowerCase(),
              )?.lang
            }
          </span>
        </MenuButton>
        <MenuList className="language-menu !border-0 p-4 shadow-[0px_4px_24px_rgba(0,0,0,0.15)]">
          {possibleStores
            ?.filter((item) => {
              if (currentLand === 'CH') return item.lang.toLowerCase() !== currentLang
              else return item.land !== currentLand
            })
            ?.sort((a, b) => {
              if (a.land === currentLand) return -1
              if (b.land === currentLand) return 1
              return a.land < b.land ? -1 : 1
            })
            ?.map((country) => (
              <MenuItem
                key={country.land + country.lang}
                className="!hover:bg-white flex w-full flex-row justify-between"
                title={t(country.name)}
                onSelect={async () => {
                  if (country.land === 'CH') {
                    cookies.set('NEXT_LOCALE', `${country.lang.toLowerCase()}`, {
                      expires: new Date(new Date().getTime() + 864000 * 1000),
                      secure: process.env.NODE_ENV === 'production',
                      sameSite: 'strict',
                    })
                    if (currentLand === 'CH') {
                      queryClient.clear()
                      await router.push('/', '/', {
                        locale: `${country.lang.toLowerCase()}`,
                      })
                    } else {
                      window.location.href = country.url
                    }
                  } else {
                    window.location.href = country.url
                  }
                }}
              >
                <span>
                  {t(country.name)} ({country.lang})
                </span>
                <Image
                  src={`/images/Flags/${country.land}.svg`}
                  placeholder="empty"
                  width={16}
                  height={16}
                  alt={country.land ?? 'flag-icon'}
                />
              </MenuItem>
            ))}
        </MenuList>
      </Menu>
    </div>
  )
}
