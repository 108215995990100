import { ProductPrice } from '@magentoTypes'

export const mapPrice = (
  type: 'min' | 'max',
  price: number,
  specialPrice: number,
  variations: Array<Record<string, any>> = [],
): ProductPrice => {
  if (variations.length > 0) {
    const prices = variations.map((variation) => variation?.data?.price).filter(Number)
    if (type === 'min') {
      const foundPrice = Math.min(...prices)
      return {
        regular_price: { value: foundPrice },
        final_price: { value: foundPrice },
      }
    }

    const foundPrice = Math.max(...prices)
    return {
      regular_price: { value: foundPrice },
      final_price: { value: foundPrice },
    }
  }
  return {
    regular_price: { value: price },
    final_price: { value: specialPrice || price },
  }
}
