import { Image } from '~/elements/Image/Image'
import clsx from 'clsx'
import { useTranslation } from 'next-i18next'
import { useEffect, useState } from 'react'
import { SearchResultSuggestions } from './CioSearchResultSuggestions'
import { SearchResultProductList } from './CioSearchResultProductList'
import { useAutocomplete } from '~/lib/Context/AutocompleteProvider'
import { useRouter } from 'next/router'
import { SearchNotFoundResults } from './CioSearchNotFoundResults'

type SearchMobileFlyoutProps = {
  onClose: () => void
}

enum SearchMobileFlyoutEnum {
  SEARCH_SUGGESTIONS = 'searchSuggestions',
  PRODUCT_LIST = 'productList',
}

export const SearchMobileFlyout = ({ onClose }: SearchMobileFlyoutProps) => {
  const { searchSuggestions, products, searchValue, loading } = useAutocomplete()

  const { t } = useTranslation()
  const router = useRouter()
  const [isEmptySearch, setIsEmptySearch] = useState(false)
  useEffect(() => {
    setIsEmptySearch(
      products.length === 0 &&
        searchSuggestions.length === 0 &&
        searchValue.trim().length !== 0 &&
        !loading,
    )
  }, [products, searchSuggestions, searchValue, loading])

  const handleSuggestionClick = async (suggestion: string) => {
    await router.push(`/search?q=${suggestion}`, undefined, {
      scroll: true,
    })
  }

  const tabs = [
    {
      id: SearchMobileFlyoutEnum.SEARCH_SUGGESTIONS,
      label: t('Search suggestions'),
    },
    {
      id: SearchMobileFlyoutEnum.PRODUCT_LIST,
      label: t('Products'),
    },
  ]
  const [activeTab, setActiveTab] = useState(tabs[0])

  return (
    <div
      className={clsx(
        'relative max-h-[calc(100vh-170px)] w-screen overflow-y-scroll rounded-b-[5px] bg-primary-white px-4 pt-7.5 pb-7.5 transition-all duration-1000 ease-in-out',
      )}
      data-cnstrc-autosuggest
    >
      <div className="flex items-center justify-between">
        <div className="flex gap-2.5">
          {!isEmptySearch &&
            tabs.map((tab) => (
              <button
                key={tab.label}
                className={clsx(
                  activeTab.id === tab.id ? 'text-sg-black' : 'text-sg-dark-grey',
                  'text-15-22-sg',
                )}
                onClick={() => setActiveTab(tab)}
              >
                {tab.label}
              </button>
            ))}
        </div>
        <button onClick={onClose} className="h-6 w-6">
          <Image
            src="/images/Icons/close.svg"
            width={24}
            height={24}
            placeholder="empty"
            alt="close-icon"
          />
        </button>
      </div>
      {isEmptySearch && <SearchNotFoundResults searchValue={searchValue} />}
      <div className="mt-7.5 overflow-auto">
        {activeTab.id === SearchMobileFlyoutEnum.SEARCH_SUGGESTIONS ? (
          <SearchResultSuggestions
            suggestions={searchSuggestions}
            handleSuggestionClick={handleSuggestionClick}
          />
        ) : (
          <SearchResultProductList products={products} />
        )}
      </div>
    </div>
  )
}
