import { Item } from '@constructor-io/constructorio-client-javascript'
import { useTranslation } from 'next-i18next'
import { SearchResultsProductTile } from './CioSearchResultsProductTile'

type SearchResultProductListProps = {
  products: Item[]
  className?: string
}
export const SearchResultProductList = ({ products }: SearchResultProductListProps) => {
  const { t } = useTranslation()

  return (
    <div>
      {products.length > 0 && (
        <p className="hidden pb-7.5 text-[15px] font-normal leading-[22px] lg:block">
          {t('Products')}
        </p>
      )}
      <div className="grid grid-cols-2 gap-x-5 gap-y-12 overflow-x-hidden pb-10 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-6">
        {products.map((product) => (
          <SearchResultsProductTile key={product?.data?.id} product={product} />
        ))}
      </div>
    </div>
  )
}
