import { useRouter } from 'next/router'

import { Money } from '@magentoTypes'

export const useFormatPrice = () => {
  const router = useRouter()

  const formatPrice = (price: Money): string => {
    if (price && typeof price.value === 'number') {
      if (isNaN(price.value)) {
        return ''
      }
      // see here https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat
      return new Intl.NumberFormat(router.locale, {
        style: 'currency',
        currency: price?.currency || 'EUR',
      }).format(price.value)
    }

    return ''
  }

  const formatPriceWithoutFraction = (price: Money): string => {
    if (price && typeof price.value === 'number') {
      if (isNaN(price.value)) {
        return ''
      }
      // see here https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat
      return new Intl.NumberFormat(router.locale, {
        style: 'currency',
        currency: price?.currency || 'EUR',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(price.value)
    }

    return ''
  }

  return { formatPrice, formatPriceWithoutFraction }
}
