import { useEffect, useState } from 'react'
import ContentLoader from 'react-content-loader'
import clsx from 'clsx'
import { useMedia } from '~/hooks/useMedia'
import styles from '../Search.module.css'

type Props = {
  className?: string
  gridColumnsNumber: number
}

export const ProductGridLoading = ({
  isOneLine,
  gridColumnsNumber,
}: {
  isOneLine?: boolean
  gridColumnsNumber: number
}) => {
  const isDesktop = useMedia('lgx')
  const isTablet = useMedia('md')

  const loop = Array(isOneLine ? gridColumnsNumber : gridColumnsNumber * 2).fill(0)

  const gridColumns = !isTablet
    ? 'grid-cols-2'
    : gridColumnsNumber === 3
    ? 'grid-cols-3'
    : gridColumnsNumber === 4
    ? 'grid-cols-4'
    : 'grid-cols-6'
  return (
    <ul className={clsx('grid', styles.productGrid, gridColumns, 'lgx:mt-12 2xl:mt-20')}>
      {loop.map((i) => (
        <li
          key={`product loader ${Math.random()}`}
          className="relative flex h-[300px] flex-col items-center justify-center lg:h-[350px] lgx:h-[300px] lgx:border-r-0 2xl:h-[340px]"
        >
          <ContentLoader
            speed={1}
            viewBox={isDesktop ? '0 0 220 300' : '-40 -60 220 300'}
            backgroundColor="#F4F4F4"
            foregroundColor="#CACACA"
            gradientRatio={1}
          >
            <rect x="10" y="0" rx="0" ry="0" width="110" height="160" />
            <rect x="0" y="180" rx="0" ry="0" width="130" height="10" />
            <rect x="10" y="200" rx="0" ry="0" width="110" height="10" />
            <rect x="20" y="220" rx="0" ry="0" width="90" height="10" />
          </ContentLoader>
        </li>
      ))}
    </ul>
  )
}

export const ResultsLoader = ({ className, gridColumnsNumber }: Props) => {
  const isDesktop = useMedia('lgx')
  const loopSuggestions = isDesktop ? [0, 1] : [0]

  return (
    <div className={clsx('flex flex-col lgx:flex-row')}>
      <aside
        className={clsx(
          '4xl:mr-[500px] 4xl:w-1/12 min-h-[298px] w-[70%] p-4 sm:w-1/3 lg:w-1/4 lg:pt-8 lgx:mr-20 lgx:min-h-full lgx:w-1/3 2xl:mr-48 2xl:w-1/6 4k:w-1/12',
          className,
        )}
      >
        <ul className={clsx('')}>
          {loopSuggestions.map((i) => (
            <li key={`result loader ${i}`}>
              <ContentLoader
                speed={3.3}
                viewBox={'0 0 100 110'}
                backgroundColor="#F4F4F4"
                foregroundColor="#CACACA"
                gradientRatio={1}
              >
                <rect x="0" y="0" rx="0" ry="0" width="80" height="7" />
                <rect x="0" y="15" rx="0" ry="0" width="60" height="7" />
                <rect x="0" y="30" rx="0" ry="0" width="45" height="7" />
                <rect x="0" y="45" rx="0" ry="0" width="50" height="7" />
                <rect x="0" y="60" rx="0" ry="0" width="35" height="7" />
                <rect x="0" y="75" rx="0" ry="0" width="60" height="7" />
              </ContentLoader>
            </li>
          ))}
        </ul>
      </aside>
      <ProductGridLoading gridColumnsNumber={gridColumnsNumber} isOneLine={false} />
    </div>
  )
}
