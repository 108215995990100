import { NavigationElement, useHeaderQuery } from '@contentfulTypes'
import { useRouteQuery } from '@magentoTypes'
import { useQueryClient } from '@tanstack/react-query'
import clsx from 'clsx'
import { DEFAULT_LOCALE } from 'config/constants'
import { createGraphQLClient } from 'graphql/contentfulClient'
import { createMagentoClient } from 'graphql/magentoClient'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { KeyboardEvent, useContext } from 'react'
import { usePreviewMode } from '~/hooks/usePreviewMode'
import { UIContext } from '~/lib/Context/UIStore'
import { createLanguageRegionLocale } from '~/lib/createLanguageRegionLocale'
import { NavItem } from './NavItem'
import { Image } from '~/elements/Image/Image'

export const FirstLevel = ({}) => {
  const {
    desktopNaviState: state,
    desktopNaviDispatch: dispatch,
    setSearchToggle,
  } = useContext(UIContext)
  const preview = usePreviewMode()
  const router = useRouter()
  const queryClient = useQueryClient()
  const finalLocale = router.locale ? createLanguageRegionLocale(router.locale) : DEFAULT_LOCALE
  const magentoClient = createMagentoClient({ queryClient, locale: finalLocale })
  const slug = router.query['slug'] as string[]
  const stringSlug = Array.isArray(slug) ? slug.join('/') : slug
  const { data: magentoData } = useRouteQuery(
    magentoClient,
    {
      slug: stringSlug?.replace(/^-/, ''),
    },
    { enabled: !!stringSlug },
  )
  const isPDP = magentoData?.route?.type === 'PRODUCT'
  const contentfulClient = createGraphQLClient({ preview })
  const { data } = useHeaderQuery(contentfulClient, {
    preview,
    locale: finalLocale,
  })

  const navData =
    data?.pageCollection?.items?.[0]?.mainMegaNavigation?.firstLevelItemsCollection?.items
  const { t } = useTranslation()

  const handleKeyPress = (event: KeyboardEvent<HTMLButtonElement>) => {
    if (event?.key === 'Enter') {
      const secondLevel = document.getElementById('secondLevel')
      if (secondLevel) {
        window.setTimeout(() => secondLevel.focus(), 600)
      }
    }
  }

  const hasChildItems =
    data?.pageCollection?.items?.[0]?.mainMegaNavigation?.firstLevelItemsCollection?.items?.[
      state?.secondLevelIndex as number
    ]?.subElementsCollection?.items?.length

  if (
    !data ||
    data?.pageCollection?.items?.[0]?.mainMegaNavigation?.firstLevelItemsCollection?.items
      ?.length === 0
  )
    return null

  return (
    <nav className="mx-14 w-full self-end">
      <menu className="flex gap-8">
        {navData &&
          navData?.map((item, index: number) => {
            if (!item || !item.title) return null
            const subElements: boolean =
              item?.subElementsCollection?.items && item?.subElementsCollection?.items?.length > 0
                ? true
                : false
            return (
              <li
                key={item?.sys?.id}
                onMouseEnter={() => {
                  setSearchToggle?.(false)
                  dispatch?.({ type: 'set-secondLevelIndex', payload: index })
                  dispatch?.({ type: 'set-secondLevelToggle', payload: subElements })
                }}
                onFocus={() => {
                  setSearchToggle?.(false)
                  dispatch?.({ type: 'set-secondLevelIndex', payload: index })
                  dispatch?.({ type: 'set-secondLevelToggle', payload: subElements })
                }}
                className="relative mt-auto"
              >
                <NavItem
                  item={item as NavigationElement}
                  boldUnderlineOnHover={true}
                  forceSameWeight={true}
                  className={clsx(
                    'font-[16px] uppercase leading-4.5',
                    state?.secondLevelIndex === index &&
                      'relative after:absolute after:bottom-[-25px] after:left-0 after:block after:h-0.5 after:w-full after:bg-primary-black',
                  )}
                  naviLevel={'1st_level'}
                />
                <button
                  onKeyDown={handleKeyPress}
                  className={clsx(
                    'absolute top-[-10000px] left-0 flex items-center gap-2 overflow-hidden whitespace-nowrap px-1 text-p-small normal-case opacity-0 transition-opacity duration-200',
                    hasChildItems &&
                      'focus:top-6 focus:h-auto focus:w-auto focus:overflow-visible focus:opacity-100',
                  )}
                >
                  {t('jump to second level')}
                  <Image
                    src="/images/Icons/chevron-right.svg"
                    width={24}
                    height={24}
                    placeholder="empty"
                    alt="chevron-icon"
                  />
                </button>
              </li>
            )
          })}
      </menu>
    </nav>
  )
}
