import { useTranslation } from 'next-i18next'
import { useSession } from '~/hooks/useSession'
import { Image } from '~/elements/Image/Image'
import { Link } from '~/elements/Link/Link'
import { CartIcon } from '../../components/CartIcon'
import { WishlistIcon } from '../../components/WishlistIcon'

type Props = {}

export const MobileButtonBar = ({}: Props) => {
  const { t } = useTranslation()
  const [session] = useSession()

  return (
    <ul className="flex flex-wrap gap-x-[21px]">
      {!session ? (
        <li>
          <Link href="/login" prefetch={false}>
            <Image
              src={'/images/Icons/account.svg'}
              placeholder="empty"
              width={25}
              height={25}
              alt="usp-icon"
            />
          </Link>
        </li>
      ) : (
        <Link href="/login" title={t('Login')} prefetch={false}>
          <li>
            <Image
              src={'/images/Icons/account.svg'}
              placeholder="empty"
              width={25}
              height={25}
              alt="usp-icon"
            />
          </li>
        </Link>
      )}

      <li>
        <Link href="/wishlist" title={t('Wishlist')} prefetch={false}>
          <WishlistIcon />
        </Link>
      </li>
      <li>
        <Link href="/cart" title={t('Cart')} prefetch={false}>
          <CartIcon />
        </Link>
      </li>
    </ul>
  )
}
