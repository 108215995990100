import { listFilters, KlevuFetch, search, suggestions, KlevuTypeOfRecord } from '@klevu/core'
import { useEffect, useState, useCallback } from 'react'

export const useKlevuQuickSearch = (searchValue: string, numberOfProductsShown: number) => {
  //analytics should not be used in quick search
  const [products, setProducts] = useState<any[]>([])
  const [suggestionTerms, setSuggestionsTerms] = useState<string[]>([])
  const [loading, setLoading] = useState(false)
  const [noResults, setNoResults] = useState(false)

  const initalFetch = useCallback(async () => {
    setLoading(true)

    if (!searchValue.replace(/\s/g, '').length) {
      //checks if only whitespace
      setLoading(false)
      return
    }

    const modifiers = [
      listFilters({
        include: ['sku'],
        //TODO: figuring out why exclude is not working and whether you can skip this
        exclude: ['benuta_color_filter', 'benuta_form_new', 'benuta_material'],

        rangeFilterSettings: [
          {
            key: 'klevu_price',
            minMax: true,
          },
        ],
      }),
    ]

    const res = await KlevuFetch(
      search(
        searchValue,
        {
          limit: numberOfProductsShown,
          typeOfRecords: [KlevuTypeOfRecord.Product],
        },
        ...modifiers,
      ),
      suggestions(searchValue),
    )

    const searchResult = res.queriesById('search')
    if (!searchResult) {
      setLoading(false)
      return
    }
    setLoading(false)
    setNoResults(searchResult?.records?.length === 0)

    setProducts(searchResult?.records ?? [])
    setSuggestionsTerms(res.suggestionsById('suggestions')?.suggestions.map((i) => i.suggest) ?? [])
  }, [searchValue, numberOfProductsShown])

  useEffect(() => {
    initalFetch()
  }, [initalFetch])

  return {
    products,
    suggestionTerms,
    loading,
    noResults,
  }
}
